
export default {
  props: {
    component: {
      type: Object,
      default() {
        return {
          title: 'Neue Produkte',
          products: Array(10).fill({
            article_nr: 'CA0433',
            product_nr: '2159',
            title: 'Greenflower Ecoline Sanimex',
            subtitle: 'Alkoholhaltiger Duftreiniger, IGÖB empfohlen',
          }),
        }
      },
    },
  },

  data() {
    return {
      options: {
        grabCursor: true,
        slidesPerView: 'auto',

        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },

        breakpoints: {
          0: {
            spaceBetween: 16,
          },

          768: {
            spaceBetween: 32,
          },
        },
      },
    }
  },
}
